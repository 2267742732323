import { infoRef, infoCommentsRef, storage, noticeRef, functions } from "../firebase/index"
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import SITEINFO from '../constants/siteInfo'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

export const initialState = {
  info: {
    infoes: [],
    comments: [],
    input: {
      key: '',
      user: {
        companyInfo: {}
      },
      title: '',
      photos: [
        // { url: '', name: ''}
      ],
      photoFiles: [],
      willDeletePhotos: [],
      type: 'info',
      text: '',
      createAt: '',
      updateAt: '',
    },
    inputComments: {
      // [infoKey]: {
      //   user: { },
      //   infoKey: '',
      //   text: '',
      //   createdAt: '',
      // },
    },
    editComments: {
      // [infoKey]: {
      //   user: { },
      //   infoKey: '',
      //   text: '',
      //   createdAt: '',
      // },
    },
    commentDisabled: {
      // [infoKey]: true
    },
    isShowCmntField: {
      // [infoKey]: true
    },
    isShowCmnts: {
      // [infoKey]: true
    },
    isShowCmntAlls: {
      // [infoKey]: true
    },
    importantInfo: null,
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      return {
        ...initialState.info
      }
    case 'INFO_COMMENT_RESET':
      return {
        ...state,
        inputComments: {
          ...state.inputComments,
          [action.field]: ''
        }
      }
    case 'RESET_INFO_INPUT':
      return {
        ...state,
        input: initialState.info.input,
      }
    case 'SET_INFOS':
      return {
        ...state,
        infoes: action.data,
      }
    case 'SET_IMPORTANT_INFO':
      return {
        ...state,
        importantInfo: action.data,
      }
    case 'SET_INFO_COMMENTS':
      return {
        ...state,
        comments: action.data,
      }
    case 'SET_INFO_GOODS':
      const goodInfoKeys = action.data.map(good => good.infoKey)
      return {
        ...state,
        goods: action.data,
        goodKeys: goodInfoKeys,
      }
    case 'SET_PREVIEW_IMAGES':
      return {
        ...state,
        input: {
          ...state.input,
          photoFiles: action.data,
        }
      }
    case 'SET_DELETE_PHOTOS':
      // NOTE: 現在の写真から削除＋削除したい写真をセット
      let deleteData = state.input.willDeletePhotos.slice()
      return {
        ...state,
        input: {
          ...state.input,
          photos: action.data,
          willDeletePhotos: deleteData.concat(action.deleteData)
        }
      }
    case 'CHANGE_INFO_IMAGES':
      let photos = state.input.photos.slice()
      return {
        ...state,
        input: {
          ...state.input,
          photos: photos.concat([action.data]),
        }
      }
    case 'CHANGE_GOOD_INFO_KEYS':
      return {
        ...state,
        goodKeys: action.data,
      }
    case 'SET_EDIT_INFO':
      return {
        ...state,
        input: {
          ...state.input,
          ...action.data
        },
      }
    case 'CHANGE_INFO_VALUE':
      return {
        ...state,
        input: {
          ...state.input,
          [action.field]: action.data,
        }
      }
    case 'CHANGE_INFO_COMMENT_VALUE':
      const disabled = action.data === '' ? true : false
      return {
        ...state,
        inputComments: {
          ...state.inputComments,
          [action.field]: action.data
        },
        commentDisabled: {
          ...state.commentDisabled,
          [action.field]: disabled
        }
      }
    case 'CHANGE_EDIT_INFO_COMMENT_VALUE':
      return {
        ...state,
        editComments: {
          ...state.editComments,
          [action.field]: {
            ...state.editComments[action.field],
            text: action.data
          }
        },
      }
    case 'IS_SHOW_INFO_CMNT_FIELD':
      const bool = action.data
      const setComment = bool ? state.comments.filter(cmnt => cmnt.key === action.field)[0] : {}
      return {
        ...state,
        isShowCmntField: {
          ...state.isShowCmntField,
          [action.field]: bool
        },
        editComments: {
          [action.field]: setComment
        }
      }
    case 'IS_SHOW_INFO_CMNT':
      return {
        ...state,
        isShowCmnts: {
          ...state.isShowCmnts,
          [action.field]: action.data
        }
      }
    case 'IS_SHOW_INFO_CMNT_ALL':
      return {
        ...state,
        isShowCmntAlls: {
          ...state.isShowCmntAlls,
          [action.field]: action.data
        }
      }
    case 'CHANGE_IMPORTANT_INFO_KEY':
      return {
        ...state,
        importantInfoKey: action.data,
      }
    default: return state
  }
}

export const actions = {
  setComment: (user, comment, infoKey) => {
    const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')
    const pushUser = {
      admin: user.admin,
      name: user.name,
      email: user.email,
      imageUrl: user.imageUrl,
      pajaposs: user.pajaposs,
      uid: user.uid,
      userId: user.userId,
      companyId: user.companyId,
      companyInfo: {
        imageUrl: user.companyInfo.imageUrl,
        name: user.companyInfo.name,
      }
    }
    return {
      text: comment.text,
      user: pushUser,
      infoKey: infoKey,
      createdAt: now,
      updatedAt: now,
    }
  },
  setInfo: (user, info) => {
    const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')
    console.log("info!", info);


    const isImportantObj = !info.isImportantValue ?
      {} : { isImportant: info.isImportantValue }

      return {
      ...isImportantObj,
      text: info.text,
      title: info.title,
      type: info.type,
      photos: info.photos,
      user: {
        companyInfo: {
          companyId: user.companyInfo.companyId,
          imageUrl: user.companyInfo.imageUrl,
          name: user.companyInfo.name,
        },
        email: user.email,
        imageUrl: user.imageUrl,
        name: user.name,
        pajaposs: user.pajaposs,
        uid: user.uid,
        userId: user.userId,
      },
      updateAt: now,
      createAt: !!info.createAt ? info.createAt : now,
    }
  },
  storeImage: async (dispatch, file, type) => {
    // 拡張子取得
    const fileName = file.name
    const lastDot = fileName.lastIndexOf('.')
    const ext = fileName.substring(lastDot + 1)

    // ファイル名決定
    const unixms = dayjs().valueOf()
    const rand = Math.floor(Math.random() * 101)
    const newFileName = `${unixms}_${rand}.${ext}`
    const dataFolder = dayjs().format('YYYY/MM/DD')

    let refPath = `info/${dataFolder}`
    let message = '画像'

    let newUrl = ''
    let result = {}

    // ストレージへのパスを生成
    const uploadRef = storage.ref(refPath).child(newFileName)
    console.log("upload")

    const setUrl = (url) => (newUrl = url)

    const setResult = (obj) => (result = obj)

    const uploadImage = () => uploadRef.put(file)
      .then(snapshot => { console.log("done upload image") })
      .catch(error => {
        console.log(error)
        throw new Error(`${message}の登録に失敗しました。 ${error.message}`)
      })

    const getImage = () => uploadRef
      .getDownloadURL()
      .then(async (url) => setUrl(url))
      .catch(function (error) {
        console.log('error get user image', error)
        throw new Error(`${newFileName} ${message}の取得に失敗しました。 ${error.message}`)
      })

    // 処理
    try {
      await uploadImage()
      await getImage()
      await setResult({ url: newUrl, name: `${refPath}/${newFileName}` })
      console.log("result", result)
    } catch (error) {
      throw new Error(error)
    }
    return result
  },
  getInfos: (dispatch) => {
    console.log("get info")
    infoRef
      .orderByKey()
      .on('value', (snapshot) => {
        const info = snapshot.val()
        if (info === null) { return dispatch({ type: 'SET_INFOS', data: [] }) }
        const entries = Object.entries(info).reverse()
        const newInfo = entries.map((entry) => {
          const [key, content] = entry
          return { key, ...content }
        })
        dispatch({ type: 'SET_INFOS', data: newInfo })
      })
  },
  getImportantInfo: (dispatch, importantInfoKey) => {
    console.log("get importantInfo")

    if(!importantInfoKey) {
      dispatch({ type: 'SET_IMPORTANT_INFO', data: null })
      return
    }

    infoRef
      .child(importantInfoKey)
      .on('value', (snapshot) => {
        console.log("infoRef");

        dispatch({ type: 'SET_IMPORTANT_INFO', data: snapshot.val() })
      })
  },
  // changeGood: (dispatch, props) => {
  //   console.log("changeGood")
  //   const { caseGoods, goodCaseKeys, caseKey, user } = props

  //   const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')
  //   const copyArr = goodCaseKeys.slice()
  //   const isAdd = copyArr.includes(caseKey) ? false : true

  //   if (isAdd) {
  //     goodsRef.push({
  //       user: user,
  //       caseKey: caseKey,
  //       createdAt: now,
  //       type: 'case',
  //     })
  //     dispatch({ type: 'CHANGE_GOOD_CASE_KEYS', data: copyArr.concat(caseKey) })
  //   } else {
  //     const goods = caseGoods.filter(good => good.caseKey === caseKey)
  //     goods.map(g => (
  //       // 削除する
  //       goodsRef.child(g.key).remove()
  //         .then(() => {
  //           const index = copyArr.indexOf(caseKey)
  //           copyArr.splice(index, 1)
  //           dispatch({ type: 'CHANGE_GOOD_CASE_KEYS', data: copyArr })
  //         })
  //     ))
  //   }
  // },
  // getGoods: (dispatch, uid) => {
  //   goodsRef
  //     .orderByKey()
  //     .on('value', (snapshot) => {
  //       console.log("get good")
  //       const goods = snapshot.val()
  //       if (goods === null) { return dispatch({ type: 'SET_GOODS', data: [] }) }
  //       const entries = Object.entries(goods).reverse()
  //       const goodsOfUser = entries.filter(g => g[1].user.uid === uid)
  //       const newGoods = goodsOfUser.map((entry) => {
  //         const [key, content] = entry
  //         return { key, ...content }
  //       })
  //       dispatch({ type: 'SET_GOODS', data: newGoods })
  //     })
  // },
  getComments: (dispatch) => {
    console.log("get comment")
    infoCommentsRef
      .orderByKey()
      .on('value', (snapshot) => {
        const comments = snapshot.val()
        if (comments === null) { return dispatch({ type: 'SET_INFO_COMMENTS', data: [] }) }
        const entries = Object.entries(comments)
        const newComments = entries.map((entry) => {
          const [key, content] = entry
          return { key, ...content }
        })
        dispatch({ type: 'SET_INFO_COMMENTS', data: newComments })
      })
  },
  updateComment: (dispatch, comment, editComments) => {
    console.log("UpdateComment")
    const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')
    const newCmnt = editComments[comment.key]
    infoCommentsRef.child(comment.key)
      .update({
        ...newCmnt,
        updatedAt: now
      })
    dispatch({ type: 'IS_SHOW_INFO_CMNT_FIELD', field: comment.key, data: false })
  },
  postComment: (dispatch, props) => {
    const { text, infoKey, user } = props
    const data = actions.setComment(user, { text: text }, infoKey)
    infoCommentsRef.push(data)
    dispatch({ type: 'INFO_COMMENT_RESET', field: infoKey })
  },
  sendNotice: async (input, doNotify) => {
    // console.log("sendNotice")
    // doNotify表示のために、事前に取得しているので、ここでは再取得しない
    if (!doNotify) return

    const msg = `
${SITEINFO.adminName}からのお知らせです。
==========================================
${input.title}
${SITEINFO.appUrl + SITEINFO.path.info}
    `
    let notices = {}
    const setNotices = async (data) => { return notices = { ...notices, ...data } }
    await setNotices((await noticeRef.child('all').once('value')).val())
    await setNotices((await noticeRef.child('infoAll').once('value')).val())
    const func = functions.httpsCallable("notice")
    func({ notices: notices, msg: msg })
      .then(res => {
        console.log(res);
      }).catch(e => {
        console.log(e);
      })
  },
}
