import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { consultCommentsRef, consultGoodsRef, consultRef, functions, noticeRef, userRef } from '../firebase'
import SITEINFO from '../constants/siteInfo'
import { actions as adminActions } from './useAdminReducer'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

export const initialState = {
  consult: {
    consults: [],
    content: {
      user: {},
      title: '',
      text: '',
      to: [],
      createAt: '',
      updateAt: '',
    },
    comments: [
      // {
      //   key: '',
      //   consultKey: '',
      //   user: {},
      //   to: {
      //     name: action.data.name,
      //     uid: action.data.uid,
      //     type: action.type,
      //     key: action.key,
      //   },
      //   text: '',
      //   createdAt: '',
      //   updatedAt: '',
      // }
    ],
    goods: [
      // {
      //   "consultKey": '',
      //   "uid": '',
      //   "type": 'consult'
      //   "createdAt": ''
      //   "updatedAt": '',
      // }
    ],
    goodKeys: [],
    input: {
      user: {},
      text: '',
      title: '',
      createAt: '',
      updateAt: '',
      consultTo: [],
    },
    inputComment: {
      text: '',
      user: {},
      to: {
        name: '',
        uid: '',
        type: '',
        key: '',
      },
      createdAt: '',
      updatedAt: '',
    },
    editComments: {},
    commentDisabled: true,
    isShowCmntField: {
      // [consultKey]: true
    },
    isShowCmnts: {},
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      return {
        ...initialState.consult
      }
    case 'RESET_INDIV_CONSULT':
      return {
        ...state,
        content: initialState.consult.content,
      }
    case 'RESET_CONSULT_INPUT':
      return {
        ...state,
        input: initialState.consult.input,
      }
    case 'RESET_CONSULT_COMMENT':
      return {
        ...state,
        inputComment: initialState.consult.inputComment,
      }
    case 'SET_CONSULTS':
      return {
        ...state,
        consults: action.data,
      }
    case 'SET_CONSULT':
      return {
        ...state,
        content: action.data,
      }
    case 'SET_TO_REPLY':
      return {
        ...state,
        inputComment: {
          ...state.inputComment,
          to: {
            name: action.data.name,
            uid: action.data.uid,
            type: action.contentType,
            key: action.key,
          }
        },
      }
    case 'RESET_TO_REPLY':
      return {
        ...state,
        inputComment: {
          ...state.inputComment,
          to: initialState.consult.inputComment.to
        },
      }
    case 'SET_CONSULT_COMMENTS':
      return {
        ...state,
        comments: action.data,
      }
    case 'SET_CONSULT_GOODS':

      return {
        ...state,
        goods: action.data,
      }
    case 'SET_EDIT_CONSULT':
      return {
        ...state,
        input: action.data,
      }
    case 'CHANGE_CONSULT_VALUE':
      return {
        ...state,
        input: {
          ...state.input,
          [action.field]: action.data,
        }
      }
    case 'CHANGE_CONSULT_COMMENT_VALUE':
      const disabled = action.data === '' ? true : false
      return {
        ...state,
        inputComment: {
          ...state.inputComment,
          text: action.data
        },
        commentDisabled: disabled,
      }
    case 'CHANGE_EDIT_CONSULT_COMMENT_VALUE':
      return {
        ...state,
        editComments: {
          ...state.editComments,
          [action.field]: {
            ...state.editComments[action.field],
            text: action.data
          }
        },
      }
    case 'IS_SHOW_CONSULT_CMNT_FIELD':
      const bool = action.data
      const setComment = bool ? state.comments.filter(cmnt => cmnt.key === action.field)[0] : {}
      return {
        ...state,
        isShowCmntField: {
          ...state.isShowCmntField,
          [action.field]: bool
        },
        editComments: {
          [action.field]: setComment
        }
      }
    case 'IS_SHOW_CONSULT_CMNT':
      return {
        ...state,
        isShowCmnts: {
          ...state.isShowCmnts,
          [action.field]: action.data
        }
      }
    default: return state
  }
}

export const actions = {
  setConsult: (user, consult) => {
    const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')
    return {
      text: consult.text,
      title: consult.title,
      consultTo: consult.consultTo,
      user: {
        companyInfo: {
          companyId: user.companyInfo.companyId,
          imageUrl: user.companyInfo.imageUrl,
          name: user.companyInfo.name,
        },
        email: user.email,
        imageUrl: user.imageUrl,
        name: user.name,
        pajaposs: user.pajaposs,
        uid: user.uid,
        userId: user.userId,
      },
      updateAt: now,
      createAt: now,
    }
  },
  getConsults: (dispatch) => {
    consultRef
      .orderByChild('updateAt')
      .on('value', (snapshot) => {
        console.log("get consult")
        const list = snapshot.val()
        if (list === null) { return dispatch({ type: 'SET_CONSULTS', data: [] }) }
        const entries = Object.entries(list).reverse()
        const newConsults = entries.map((entry) => {
          const [key, c] = entry
          return { key, ...c }
        })
        dispatch({ type: 'SET_CONSULTS', data: newConsults })
      })
  },
  getGoods: (dispatch) => {
    consultGoodsRef
      .orderByKey()
      .on('value', (snapshot) => {
        console.log("get good")
        const goods = snapshot.val()
        if (goods === null) { return dispatch({ type: 'SET_CONSULT_GOODS', data: [] }) }
        const entries = Object.entries(goods)
        const newGoods = entries.map((entry) => {
          const [key, c] = entry
          return { key, ...c }
        })
        dispatch({ type: 'SET_CONSULT_GOODS', data: newGoods })
      })
  },
  getComments: (dispatch) => {
    consultCommentsRef
      .orderByChild('createdAt')
      .on('value', (snapshot) => {
        console.log("get comment")
        const comments = snapshot.val()
        if (comments === null) { return dispatch({ type: 'SET_CONSULT_COMMENTS', data: [] }) }
        const entries = Object.entries(comments)
        const newComments = entries.map((entry) => {
          const [key, c] = entry
          return { key, ...c }
        })
        dispatch({ type: 'SET_CONSULT_COMMENTS', data: newComments })
      })
  },
  updateComment: (dispatch, comment, editComments) => {
    console.log("UpdateComment")
    const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')
    const newCmnt = editComments[comment.key]
    consultCommentsRef.child(comment.key)
      .update({
        ...newCmnt,
        updateAt: now
      })
    dispatch({ type: 'IS_SHOW_CONSULT_CMNT_FIELD', field: comment.key, data: false })
  },
  changeGood: (dispatch, props) => {
    console.log("good")
    const { good, consultKey, consultCmntKey, type, user } = props

    const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')
    const isAdd = !!good ? false : true
    const keys = !!consultCmntKey ? { consultCmntKey: consultCmntKey } : {}

    if (isAdd) {
      console.log("push good")
      consultGoodsRef.push({
        uid: user.uid,
        consultKey: consultKey,
        ...keys,
        createdAt: now,
        type: type,
      })
    } else {
      console.log("remove good")
      consultGoodsRef.child(good.key).remove()
    }
  },
  postComment: (dispatch, inputComment, user, consultKey, content) => {
    console.log("post comment");
    const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')
    const newComment = {
      ...inputComment,
      consultKey: consultKey,
      user: user,
      createdAt: now,
      updatedAt: now,
    }
    
    consultCommentsRef.push(newComment).then(async (ref) => {
      // 通知を送信
      const msg = `
相談部屋に新しい返信があります。
==========================================
${user.companyInfo.name}
${inputComment.text}
${SITEINFO.appUrl + SITEINFO.path.consult}/${consultKey}
`
      // 通知先を取得
      let notices = {}
      
      // 元の投稿者のユーザー情報を取得（自分自身でない場合のみ）
      if (content.user.userId !== user.userId) {
        const originalPosterData = (await userRef.child(content.user.userId).once('value')).val()
        if (originalPosterData && originalPosterData.noticeRoomId && originalPosterData.noticeToken) {
          notices[content.user.userId] = {
            noticeRoomId: originalPosterData.noticeRoomId,
            noticeToken: originalPosterData.noticeToken,
            userId: content.user.userId
          }
        }
      }

      // スレッド参加者（コメント投稿者）の通知設定を取得
      const comments = (await consultCommentsRef.orderByChild('consultKey').equalTo(consultKey).once('value')).val()
      if (comments) {
        const uniqueCommenters = new Set(); // 重複を防ぐためのSet
        await Promise.all(Object.entries(comments).map(async ([commentKey, comment]) => {
          // 新しく投稿したコメントと自分自身は除外
          if (commentKey !== ref.key && comment.user.userId !== user.userId && !uniqueCommenters.has(comment.user.userId)) {
            uniqueCommenters.add(comment.user.userId);
            const commenterData = (await userRef.child(comment.user.userId).once('value')).val()
            if (commenterData && commenterData.noticeRoomId && commenterData.noticeToken) {
              notices[comment.user.userId] = {
                noticeRoomId: commenterData.noticeRoomId,
                noticeToken: commenterData.noticeToken,
                userId: comment.user.userId
              }
            }
          }
        }))
      }

      // 通知を送信
      if (Object.keys(notices).length > 0) {
        const func = functions.httpsCallable("notice")
        await func({ notices: notices, msg: msg })
          .then(res => {
            console.log("通知送信成功:", res);
          }).catch(e => {
            console.log("通知送信エラー:", e);
            // エラーが発生しても処理は継続
          })
      }
    }).catch(error => {
      console.error("コメント投稿エラー:", error);
    })

    dispatch({ type: 'RESET_CONSULT_COMMENT' })
    dispatch({ type: 'RESET_COMMON' })
  },
  sendNotice: async (input, user, dispatch) => {
    // doNotifyの値を再取得
    const doNotify = await adminActions.getDoNotify(dispatch)
    // console.log("sendNotice doNotify: ", doNotify)
    if (!doNotify) return
    const msg = `
相談が投稿されました。
==========================================
${user.companyInfo.name}
${input.title}
${SITEINFO.appUrl + SITEINFO.path.consult}
`
    let notices = {}
    const setNotices = async (data) => { return notices = { ...notices, ...data } }
    await setNotices((await noticeRef.child('all').once('value')).val())
    await setNotices((await noticeRef.child('consultAll').once('value')).val())

    // 重複を削除
    const arr = Object.entries(notices).map(([key, value]) => ({ [key]: value }))
    const result = arr.filter((element, index, self) => self.findIndex(e => e.userId === element.userId) === index)
    const obj = {}
    result.forEach((data, i) => { Object.entries(data).map(([key, value]) => obj[key] = value) })

    console.log("sendNotice obj: ", obj)
    const func = functions.httpsCallable("notice")
    await func({ notices: obj, msg: msg })
      .then(res => {
        console.log(res);
      }).catch(e => {
        console.log(e);
      })
  }
}
