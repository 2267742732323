import React, { useEffect, useContext, useRef, useState } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  List,
  OutlinedInput,
  Typography,
} from '@material-ui/core'
import {
  Edit as EditIcon,
  Reply as ReplyIcon,
} from '@material-ui/icons'
import withWidth from '@material-ui/core/withWidth'
import MainTemplate from '../templates/MainTemplate'
import { GlobalContext } from '../../hooks/reducer'
import { actions } from "../../hooks/useLoginUserReducer"
import { actions as consultActions } from "../../hooks/useConsultReducer"
import { actions as commonActions } from "../../hooks/useCommonReducer"
import CustomModal from '../common/CustomModal'
import thumbsUpContainIcon from '../../assets/fontawesome/svgs/solid/thumbs-up.svg'
import thumbsUpIcon from '../../assets/fontawesome/svgs/regular/thumbs-up.svg'
import commentIcon from '../../assets/fontawesome/svgs/regular/comment-alt.svg'
import { Comment } from '../common/Comment'
import { commonTheme } from '../../materialui/theme'
import { Back } from '../common/Back'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles((theme) => ({
  wrap: {
    marginTop: '40px', padding: 0
  },
  header: {
    backgroundColor: commonTheme.palette.primary.main,
    color: '#ffffffff',
    borderStartStartRadius: '4px',
    borderStartEndRadius: '4px',
  },
  contentWrap: {},
  title: {
    color: '#ffffff',
  },
  text: {},
  consultTo: {
    marginBottom: '10px'
  },
  consultToSpan: {
    fontWeight: 'bold',
    margin: '0 5px',
  },
  contentBtm: {
    marginTop: '20px'
  },
  user: {
    paddingRight: '5px',
    alignSelf: 'baseline',
  },
  company: {
    fontSize: '0.75rem',
    alignSelf: 'baseline',
  },
  gooded: {
    fontWeight: 'bold'
  },
  good: {
    paddingLeft: 0,
  },
  gray: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  comment: {
    marginTop: '5px',
  },
  commentIcon: {
    paddingLeft: 0,
  },
  commnetTtl: {
    fontWeight: 'bold'
  },
  commentList: {
    paddingTop: 0
  },
  formWrap: {
    marginTop: '10px'
  },
  form: {
    width: '100%'
  },
  submit: {
    margin: 0,
    width: '100%'
  }
}))

const IndivConsult = React.memo((props) => {
  const { consultKey } = useParams()
  const history = useHistory()
  const classes = useStyles()
  const ref = useRef()

  const { state: { loginUser, consult, common }, dispatch } = useContext(GlobalContext)
  const { isOpen, scrollTo } = common
  const { user } = loginUser
  const {
    goods, comments, commentDisabled, isShowCmntField, inputComment,
    editComments, consults, content
  } = consult

  const [editable, setEditable] = useState(false)

  const goodNum = goods.filter(g => (g.consultKey === content.key) && !g.consultCmntKey).length

  // 初回mount
  useEffect(() => {
    // ログインしていない場合、ログインページに遷移
    console.log('初回')
    if (!loginUser.isLogin) { actions.loginCheck(dispatch, history) }
    if (loginUser.isLogin) {
      if (consults.length <= 0) {
        if (process.env.NODE_ENV === 'development') { console.log('セット') }
        consultActions.getConsults(dispatch)
        consultActions.getComments(dispatch)
        consultActions.getGoods(dispatch)
      }
      if (consults.length > 0 && !content.title) {
        const setConsult = consults.find(c => c.key === consultKey)
        dispatch({ type: 'SET_CONSULT', data: setConsult })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser.isLogin, content, consults])

  useEffect(() => {
    // NOTE: 個別ページに直接アクセスしたとき
    if (loginUser.isLogin && Object.keys(content) <= 0) {
      const thisConsult = consults.filter(c => c.key === consultKey)
      dispatch({ type: 'SET_CONSULT', data: thisConsult[0] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consults])

  useEffect(() => {
    return () => {
      dispatch({ type: 'COMMON_RESET' })
      dispatch({ type: 'RESET_INDIV_CONSULT' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consults])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { if (!!content.createAt) { setEditable(commonActions.editable(content.user, user)) } }, [content])

  useEffect(() => {
    if (scrollTo) { ref.current.scrollIntoView({ behavior: "smooth" }) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTo])

  // state不十分の場合、表示させない
  if (!loginUser.isLogin || !content.title) { return <MainTemplate></MainTemplate> }

  const indivComments = comments.filter((cmnt) => cmnt.consultKey === content.key)
  const indivGoods = goods.filter((g) => g.consultKey === content.key)
  const gooded = indivGoods.filter((g) => g.uid === user.uid)
  const consultGood = indivGoods.find(g => !g.consultCmntKey)

  return (
    <MainTemplate>
      {isOpen && <CustomModal />}

      {/* 戻るボタン */}
      <Back history={history} path='/consult' />

      <Container
        component={Card}
        className={classes.wrap}
      >

        {/* タイトル */}
        <CardHeader className={classes.header} title={
          <Grid container alignItems='center'>
            <Grid item component={Avatar}
              src={content.user.imageUrl}
              onClick={() => history.push(`/company/${content.user.companyInfo.companyId}`)}
              className='avatar'
              style={{ marginRight: '14px' }}
            />
            <Grid item component={Typography} variant='h5' className={classes.title}>
              {content.title}
            </Grid>
          </Grid>} />

        <CardContent className={classes.contentWrap}>

          {/* 宛先 */}
          {
            !!content.consultTo && content.consultTo.length > 0 &&
            <Typography variant='body2' component='p' className={classes.consultTo}>
              宛先: {content.consultTo.map((to, i) => (
              <span className={classes.consultToSpan} key={i}>{to.label}</span>
            ))}
            </Typography>
          }

          {/* 相談内容 */}
          <Typography
            variant='body1'
            className={clsx('indention', classes.text)}
            dangerouslySetInnerHTML={commonActions.changeToURL(content.text)}
          />

          {/* 投稿情報・ いいね・返信ボタン */}
          <Grid
            container
            direction='row'
            className={classes.contentBtm}
            justifyContent='space-between'
          >

            {/* 投稿者・投稿時間 */}
            <Grid container item direction='column'>

              {/* 投稿者 */}
              <Grid
                container
                item
                direction='row'
                justifyContent='flex-end'
              >
                <Grid
                  component={Typography}
                  variant='body2'
                  className={clsx(classes.gray, classes.user)}
                >{content.user.name}</Grid>
                <Grid
                  component={Typography}
                  variant='body2'
                  className={clsx(classes.gray, classes.company)}
                >{content.user.companyInfo.name}</Grid>
              </Grid>

              {/* 投稿時間 */}
              <Grid
                item
                component={Typography}
                variant='caption'
                align='right'
                className={classes.gray}
              >
                {dayjs(content.updateAt).tz().format('YYYY年MM月DD日 HH:mm')}
              </Grid>

            </Grid>

            {/* 編集ボタン */}
            {
              editable && <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: '12px 0 5px auto' }}
                onClick={() => history.push(`/consult/edit/${consultKey}`)}
              >
                <EditIcon fontSize='small' style={{ marginRight: '5px' }} />
                相談を編集する
              </Button>
            }

            {/* いいね・返信ボタン */}
            <Grid container item justifyContent='flex-end'>

              {/* いいね */}
              <Grid
                item
                component={IconButton}
                className={classes.good}
                onClick={() => consultActions.changeGood(dispatch, {
                  consultKey: content.key,
                  user: loginUser.user,
                  good: consultGood,
                  type: 'consult'
                })}
              >
                {
                  !!consultGood ? (
                    <React.Fragment>
                      <CardMedia
                        className='global_icon global_icon_txt'
                        image={thumbsUpContainIcon}
                      />
                      <Typography
                        variant="caption"
                        color="secondary"
                        className={classes.gooded}
                      >
                        いいね！
                      </Typography>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <CardMedia
                        className='global_icon global_icon_txt'
                        image={thumbsUpIcon}
                      />
                      <Typography variant="caption">いいね！</Typography>
                    </React.Fragment>
                  )
                }
                {goodNum > 0 && <Chip className='good' size="small" label={goodNum} />}
              </Grid>

              {/* 返信ボタン */}
              <Grid
                item
                component={IconButton}
                className={classes.reply}
                onClick={() => {
                  dispatch({
                    type: 'SET_TO_REPLY',
                    data: content.user,
                    contentType: 'consult',
                    key: consultKey,
                  })
                  dispatch({ type: 'CHANGE_SCROLL_TO', data: 'comment' })
                }}
              >
                <CardMedia
                  className='global_icon global_icon_txt'
                  component={ReplyIcon}
                />
                <Typography variant="caption">返信する</Typography>
              </Grid>

            </Grid>

          </Grid>

          <Divider />

          {/* コメント */}
          <div className={classes.comment}>

            <IconButton
              className={clsx(classes.commentIcon, classes.gray)}
              disabled={true}
              component='div'
            >
              <CardMedia
                className='global_icon global_icon_txt'
                image={commentIcon}
              />
              <Typography variant="caption" className={classes.commnetTtl}>
                コメント
              </Typography>
            </IconButton>

            {
              indivComments.length > 0 && <React.Fragment>

                {/* コメント一覧 */}
                <List className={classes.commentList}>
                  {
                    indivComments.map((comment, i) => {
                      const indivCmntGoods = indivGoods.filter((g) => {
                        return !!g.consultCmntKey && g.consultCmntKey === comment.key
                      })
                      return <Comment
                        key={i}
                        comment={comment}
                        isShowCmntField={isShowCmntField}
                        editComments={editComments}
                        handleDispatch={dispatch}
                        loginUser={loginUser}
                        isShowCmnts={true}
                        commentActions={consultActions}
                        commentKey={comment.key}
                        goods={indivCmntGoods}
                        good={gooded.find(g => g.consultCmntKey === comment.key)}
                        contentType='consultCmnt'
                        defaultShowAll={true}
                        forcedChangeURL={true}
                      />
                    })
                  }
                </List>

              </React.Fragment>
            }

            {/* コメントフォーム */}
            <div ref={ref} className={classes.formWrap}>

              <FormControl variant="outlined" className={classes.form}>
                <OutlinedInput
                  value={inputComment.text}
                  onChange={(e) => dispatch({
                    type: 'CHANGE_CONSULT_COMMENT_VALUE',
                    data: e.target.value
                  })}
                  aria-describedby=""
                  startAdornment={
                    !!inputComment.to.name ? <InputAdornment position="start">
                      <Chip
                        variant="outlined"
                        size="small"
                        label={inputComment.to.name}
                        onDelete={() => dispatch({ type: 'RESET_TO_REPLY' })}
                      />
                    </InputAdornment> : ''
                  }
                  labelWidth={0}
                  placeholder="ここにコメントを入力する"
                  className={classes.textField}
                  multiline
                />
              </FormControl>

              <Button
                variant="contained"
                color="secondary"
                className={classes.submit}
                onClick={() => consultActions.postComment(
                  dispatch, inputComment, user, consultKey, content
                )}
                disabled={commentDisabled}
              >
                コメントを投稿する
              </Button>

            </div>

          </div>

        </CardContent>

      </Container>

    </MainTemplate >
  )
})
export default withWidth()(IndivConsult)
