import React, { useEffect, useContext } from 'react'
import MODAL from "../../constants/modal"
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import clsx from 'clsx'
import { consultRef } from "../../firebase/index"
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  DeleteForever as DeleteForeverIcon,
} from '@material-ui/icons'
import MainTemplate from '../templates/MainTemplate'
import Select from 'react-select'
import { commonTheme } from "../../materialui/theme"
import { GlobalContext } from '../../hooks/reducer'
import { actions } from "../../hooks/useLoginUserReducer"
import { actions as consultActions } from "../../hooks/useConsultReducer"
import CustomModal from "../common/CustomModal"
import { Back } from '../common/Back'
import Caution from './common/Caution'
import SITEINFO from "../../constants/siteInfo"

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

// 宛先マスタ
const consultToList = [
  { id: 1, value: 'ikemoto', label: '池本さん' },
  { id: 2, value: 'pajaposs', label: SITEINFO.adminName },
  { id: 3, value: 'all', label: '全員' },
]

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: 'unset',
    paddingTop: '40px',
  },
  form: {
    margin: '10px 0',
    width: '100%',
  },
  formTag: {
    marginRight: '20px',
  },
  tagWrap: {
    alignItems: 'flex-end',
    margin: '25px 0px',
  },
  tagLabel: {
    margin: '0 0 4px',
    fontSize: '0.8em',
  },
  tagValue: {
    margin: '-4px 0 0',
    fontSize: '1.2em',
  },
  caution: {
    margin: '5px 0',
    color: commonTheme.palette.error.main,
    fontSize: '0.9em',
  },
  submit: {
    margin: '10px',
  },
}))

const PostConsult = React.memo((props) => {
  const { consultKey } = useParams()
  const history = useHistory()
  const classes = useStyles()

  const { state: { consult, loginUser, common }, dispatch } = useContext(GlobalContext)
  const { isLoading, disabled } = common
  const { input } = consult
  const { text, title, consultTo } = input

  // 既に選択されている宛先
  const defaultConsultTo = consultTo ? consultTo.map(tag => consultToList[tag.id - 1]) : ''

  // 初回mount
  useEffect(() => {
    // ログインしていない場合、ログインページに遷移
    if (!loginUser.isLogin) { actions.loginCheck(dispatch, history) }
    if (loginUser.isLogin && consultKey) {
      // editの場合、投稿内容取得
      consultRef.child(consultKey)
        .once('value', (snapshot) => {
          const consultData = snapshot.val()
          if (!consultData) { history.push('/consult') }
          else { dispatch({ type: 'SET_EDIT_CONSULT', data: consultData }) }
        })
    }
    return () => {
      dispatch({ type: 'COMMON_RESET' })
      dispatch({ type: 'RESET_CONSULT_INPUT' })
    }
  }, [dispatch, history, loginUser.isLogin, consultKey])

  // input監視
  useEffect(() => {
    const unallowed = (
      isLoading || !text || !title || (consultTo && consultTo.length <= 0))
    if (unallowed) {
      // ボタン無効
      !disabled && dispatch({ type: 'CHANGE_DISABLED', data: true })
    } else {
      // ボタン有効
      disabled && dispatch({ type: 'CHANGE_DISABLED', data: false })
    }
  }, [text, title, consultTo, isLoading, dispatch, disabled])

  const handlePost = async () => {
    console.log('handlePost start')
    const data = consultActions.setConsult(loginUser.user, input)
    await consultRef.push(data)

    // 通知
    consultActions.sendNotice(data, loginUser.user, dispatch)

    dispatch({ type: 'COMMON_RESET' })
    dispatch({ type: 'RESET_CONSULT_INPUT' })
    history.push('/consult')
  }

  const handleUpdate = () => {
    console.log('update start')
    const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')
    consultRef.child(consultKey)
      .update({
        ...input,
        updateAt: now
      })
    history.push(`/consult/${consultKey}`)
  }

  const SubmitButton = () => {
    const classes = useStyles()
    return <React.Fragment>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        {
          !consultKey && <Button
            variant="contained"
            color="secondary"
            onClick={() => handlePost()}
            className={clsx(classes.submit)}
            disabled={disabled}
          >質問・相談を作成する</Button>
        }
        {/* edit */}
        {
          consultKey && <React.Fragment>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => history.push(`/consult/${consultKey}`)}
              className={clsx(classes.submit)}
            >戻る</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleUpdate()}
              className={clsx(classes.submit)}
            >修正を保存する</Button>
          </React.Fragment>
        }
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        {
          consultKey && <IconButton
            edge="end"
            onClick={() => dispatch({
              type: 'IS_OPEN',
              data: { bool: true, stage: MODAL.willDelete, path: `consults/${consultKey}` }
            })}
          >
            <DeleteForeverIcon fontSize='small' style={{ marginRight: '5px' }} />
            <Typography component="span" variant="subtitle2" color='error' align='center'>
              投稿を削除する
            </Typography>
          </IconButton>
        }
      </Grid>
    </React.Fragment>
  }

  if (!loginUser.isLogin) { return <MainTemplate></MainTemplate> }

  return (
    <MainTemplate>

      {/* モーダル */}
      <CustomModal />

      {/* 戻るボタン */}
      <Back history={history} />

      <Card className={classes.card}>

        {/* 注意書き */}
        <Caution />

        <CardContent>

          <FormControl className={clsx(classes.form, classes.formTag)}>
            <p className={classes.tagLabel}>宛先</p>
            <Select
              className={classes.tag}
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
              onChange={(e) => dispatch({ type: 'CHANGE_CONSULT_VALUE', data: e, field: 'consultTo' })}
              label="宛先"
              isMulti
              options={consultToList}
              name="consultTo"
              value={defaultConsultTo}
            />
          </FormControl>

          <FormControl className={classes.form} fullWidth={true}>
            <TextField
              label='タイトル'
              value={title}
              onChange={(e) => dispatch({ type: 'CHANGE_CONSULT_VALUE', data: e.target.value, field: 'title' })}
              variant="outlined"
              required
            />
          </FormControl>

          <FormControl className={classes.form} fullWidth={true}>
            <TextField
              label='質問・相談内容'
              value={text}
              onChange={(e) => dispatch({ type: 'CHANGE_CONSULT_VALUE', data: e.target.value, field: 'text' })}
              variant="outlined"
              multiline
              minRows={8}
              required
            />
          </FormControl>

          <SubmitButton />

        </CardContent>
      </Card >

      {/* 戻るボタン */}
      <Back history={history} />
    </MainTemplate >
  )
})
export default PostConsult
